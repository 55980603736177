import { useEffect } from 'react'
import { accessTokenStore, ciApiAvailableStore } from '@root/framework/stores/app-store';
import { useStore } from '@nanostores/react';

const FetchInterceptor = () => {

    const $accessToken = useStore(accessTokenStore);

    useEffect(() => {
        if ($accessToken) {
            ciApiAvailableStore.set(true);

            const { fetch: originalFetch } = window;

            window.fetch = async (...args) => {
                let [resource, config] = args;

                if (resource.toString().includes(import.meta.env.PUBLIC_INTERCOM_API_URL)) {
                    if (!config) {
                        config = {}
                    }
                    config.headers = {
                        ...config.headers,
                        "Authorization": `Bearer ${$accessToken}`
                    }
                }

                return await originalFetch(resource, config);
            };
        }
    }, [$accessToken]);
}

export default FetchInterceptor